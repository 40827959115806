.radio-field {
  display: flex;
  flex-direction: column;
  gap: 20px;
  contain: content;
  width: fit-content;

  .ant-radio-wrapper-disabled span {
    text-shadow: 0 0 black;
  }
}

.detail-container div {
  :first-child {
    font-weight: bold;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}

.ant-form-item-children-icon {
  display: none;
}
