.account-gos-auctions-table .ant-table-thead tr .ant-table-selection-column div {
  display: none;
}

.table-title {
  font-size: 1.1em;
  padding-bottom: 20px;
  font-weight: 500;
}

.highest-bidder {
  background-color: #e5ffe5;
}