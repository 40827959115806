.summary-row {
  background-color: #fafafa;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
}

.disabled-row {
  color: #ccc;
  background-color: #f5f5f5;
}

.ant-calendar-time-picker-select-option-disabled {
  display: none;
}

.ant-input-number {
  min-width: 80px;
}

span.custom-date-picker.ant-calendar-picker {
  max-width: 160px !important;
  min-width: 155px !important;
}

th i {
  contain: size;
}