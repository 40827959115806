.detail-container div {
  :first-child {
    font-weight: bold;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}

.ant-form-item-children-icon {
  display: none;
}

.detail-container {
  .ant-row.ant-form-item {
    display: inline-flex;

    .ant-form-item-control {
      width: 60%;

      input {
        font-weight: normal !important;
      }
    }
  }
}
