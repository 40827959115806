.ant-col.ant-form-item-control-wrapper {
  white-space: nowrap;
}

.ant-form-item-required {
  font-weight: bold;

  &:before {
    font-weight: normal;
  }
}

.violation div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  span {
    padding-right: unset !important;
  }

  label::after {
    content: '';
  }
}
