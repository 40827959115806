.detail-container div {
  :first-child {
    font-weight: bold;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}

.ant-form-item-children-icon {
  display: none;
}

.detail-container {
  .ant-row.ant-form-item {
    display: inline-flex;

    .ant-form-item-control {
      width: 60%;

      input {
        font-weight: normal !important;
      }
    }
  }
}

.checkbox-tooltip div {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  line-height: normal;

  .ant-checkbox-wrapper {
    display: inline-flex;
  }

  .ant-checkbox {
    top: 0.15rem;
  }

  span {
    padding-right: unset !important;
    color: unset;
    white-space: normal;
  }

  label::after {
    content: '';
  }
}
