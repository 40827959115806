.detail-container div {
  :first-child {
    font-weight: bold;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}

.ant-col.ant-form-item-label {
  min-width: max-content;
}

.detail-container .ant-row.ant-form-item .ant-form-item-control {
  width: 70%;
}

.ant-row.ant-form-item {
  margin-bottom: unset;
}
