.conditions {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    white-space: nowrap;
    gap: 10px;
    margin-top: 0;
  }
}