.account-gos-agreements-table .ant-table-thead tr .ant-table-selection-column div {
  display: none;
}

.table-title {
  font-size: 1.1em;
  padding-bottom: 20px;
  font-weight: 500;
}

.component-table-row-actions {
  display: flex;
  align-items: center;
}

.checkbox-radio .ant-radio-inner {
  border-radius: 4px; /* Adjust the border radius as needed */
}

.checkbox-radio .ant-radio-inner::after {
  border-radius: 2px; /* Adjust the checkmark border radius as needed */
}
